/*
 * Add a class to .field wrappers
 * when the input has content
 */

export default (document => {
    const filledClass = 'filled';

    // Find the fields
    const fields = Array.prototype.slice.call(
        document.querySelectorAll('.field--inplace')
    );

    function watchInput() {
        const classAction = this.value ? 'add' : 'remove';
        this.parentNode.classList[classAction](filledClass);
    }

    // Add an event handlers to the fields
    fields.forEach(field => {
        const input = field.querySelector('input, textarea');
        input.addEventListener('keyup', watchInput);
        input.addEventListener('paste', watchInput);
        input.addEventListener('change', watchInput);
        setTimeout(() => {
            watchInput.call(input);
        }, 250);
    });
})(window.document);
