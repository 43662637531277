// Set up image slider
import Flickity from 'flickity';

export default (document => {
    const banners = Array.prototype.slice.call(
        document.querySelectorAll('.js-banner')
    );
    banners.forEach(banner => {
        new Flickity(banner, {
            cellSelector: '.js-banner-cell',
            setGallerySize: false,
            wrapAround: true,
            autoPlay: 6000,
            selectedAttraction: 0.02,
            arrowShape: {
                x0: 10,
                x1: 60,
                y1: 50,
                x2: 70,
                y2: 50,
                x3: 20,
            },
        });
    });
})(document);
