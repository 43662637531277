import Toggle from 'accessible-toggle';
import reframe from 'reframe.js';

// Reusable plugins
import './plugins/externalLinks';
import './plugins/inplace';

// Site functionality
import './modules/banner';
import './modules/podcast';

// Toggle navigation using hamburger menu
new Toggle(document.getElementById('navigation'), {
    mediaQuery: '(max-width: 1000px)',
});

// Make embedded videos responsive
reframe('iframe');
