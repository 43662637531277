/*
 * Podcast player functionality
 */

let playing;

const wrapper = document.querySelector('.js-podcasts');
if (wrapper) {
    wrapper.addEventListener('click', event => {
        if (event.target && event.target.matches('.js-podcast-player')) {
            const button = event.target;
            const audio = button.nextElementSibling;

            // If one is already playing, pause it
            if (playing === audio) {
                playing.pause();
                playing.parentNode.classList.remove('playing');
                playing = null;
                return;
            } else if (playing) {
                playing.pause();
                playing.parentNode.classList.remove('playing');
            }

            // Start the new track
            audio.play();
            audio.parentNode.classList.add('playing');
            playing = audio;
        }
    });
}
